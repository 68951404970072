// Swiper slideshow
(() => {
	new Swiper(".home-slideshow .swiper-container", {
		direction: "horizontal",
		loop: true,

		pagination: {
			el: ".home-slideshow .swiper-pagination",
		},

		navigation: {
			nextEl: ".home-slideshow .swiper-button-next",
			prevEl: ".home-slideshow .swiper-button-prev",
		},

		autoplay: true,
	});
})();